@tailwind base;
@tailwind components;
@tailwind utilities;


.box-shadow-white {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.box-shadow-black {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25)
}

.container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 500px;
  height: 300px;
  background: #f1f1f1;
  margin: 100px auto 0;
  word-break: break-all;
  border: 1px solid rgba(0, 0, 0, 0.274);
}

.content-tabs {
  flex-grow: 1;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}





/* Tamaño del scroll */
.scrollbarBlack::-webkit-scrollbar {
  width: 8px;
}

/* Estilos barra (thumb) de scroll */
.scrollbarBlack::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 4px;
}

.scrollbarBlack::-webkit-scrollbar-thumb:active {
  background-color: #5c5c5c;
}

.scrollbarBlack::-webkit-scrollbar-thumb:hover {
  background: #b3b3b3;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

/* Estilos track de scroll */
.scrollbarBlack::-webkit-scrollbar-track {
  background: #e1e1e1;
  border-radius: 4px;
}

.scrollbarBlack::-webkit-scrollbar-track:hover,
.scrollbarBlack::-webkit-scrollbar-track:active {
  background: #d4d4d4;
}

.zoom {
  transition: transform .2s;
}

.zoom:hover {
  transform: scale(1.5);
}



.text-wrap {
  white-space: unset !important;
  word-break: break-word;
}






.shadow-scroll-x {
  overflow-x: auto;

  background-color: white;
  background-image:
    /* Shadows */
    linear-gradient(to right,
      white 0%,
      white 50%,
      rgba(255, 255, 255, 0) 100%),
    linear-gradient(to left, white 0%, white 50%, rgba(255, 255, 255, 0) 100%),
    /* Shadow covers */
    linear-gradient(to right,
      rgba(0, 0, 0, 0.1) 0%,
      rgba(255, 255, 255, 0) 50%,
      rgba(255, 255, 255, 0) 100%),
    linear-gradient(to left,
      rgba(0, 0, 0, 0.1) 0%,
      rgba(255, 255, 255, 0) 50%,
      rgba(255, 255, 255, 0) 100%);
  background-repeat: no-repeat;
  background-position: left center, right center, left center, right center;
  background-size: 10px 100%, 10px 100%, 10px 100%, 10px 100%;
  /* Opera doesn't support this in the shorthand */
  background-attachment: local, local, scroll, scroll;
  -webkit-overflow-scrolling: touch;
  /* 
    Progressive enhancement of scrollbar on webkit
    Use media queries to only use on mobile */

}

.shadow-scroll-x::-webkit-scrollbar {
  width:6px;
  height: 6px;
  background-color: white;
}

.shadow-scroll-x::-webkit-scrollbar-thumb {
  background-color: #999;
  border-radius: 3px;
}

.shadow-scroll-x::-webkit-scrollbar-button {
  display: none;
}